<template>
  <div class="welcome-container">
    <h1 class="welcome-title">
      {{ $t('welcome.welcome', { name: firstname }) }}
    </h1>
    <v-btn color="primary" size="large" rounded="xl" @click="startJourney" class="markazi-text">
      {{ $t('welcome.startJourney') }}
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { userStore } from '../stores/userStore'
import { storeToRefs } from 'pinia'

const router = useRouter();
const { firstname } = storeToRefs(userStore())

// First page to show
const startJourney = () => {
  router.push('/prompt');
};

</script>

<style scoped>
.welcome-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('@/assets/peaceful-background.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
}

.welcome-title {
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: white;
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 0;
}

.start-button {
  padding: 1rem 2rem;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

.start-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}
</style>