<template>
    <div>
        <v-card class="mb-4">
            <v-card-title class="title-markazi">
                {{ t('knowledge.title') }}
                <v-spacer />
                <v-btn color="primary" prepend-icon="mdi-plus" @click="dialog = true">
                    {{ t('knowledge.addKnowledge') }}
                </v-btn>
            </v-card-title>
        </v-card>

        <!-- Create Knowledge Dialog -->
        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
                <v-card-title>{{ t('knowledge.createNew') }}</v-card-title>
                <v-card-text>
                    <v-form ref="form" @submit.prevent="saveKnowledge" v-model="formValid">
                        <v-text-field
                            v-model="newKnowledge.title"
                            :label="$t('knowledge.fields.title')"
                            :rules="[v => !!v || $t('knowledge.validation.titleRequired')]"
                            required
                        />
                        <v-textarea
                            v-model="newKnowledge.description"
                            :label="$t('knowledge.fields.description')"
                            :rules="[v => !!v || $t('knowledge.validation.descriptionRequired')]"
                            required
                        />
                        <v-select
                            v-model="newKnowledge.type"
                            :label="$t('knowledge.fields.type')"
                            :items="Object.keys(knowledgeTypes)"
                            :rules="[v => !!v || $t('knowledge.validation.typeRequired')]"
                            required
                        />
                        <template v-if="newKnowledge.type === 's3'">
                            <v-text-field
                                v-model="newKnowledge.region"
                                :label="$t('knowledge.fields.region')"
                                :rules="[v => !!v || $t('knowledge.validation.regionRequired')]"
                                required
                            />
                            <v-text-field
                                v-model="newKnowledge.bucket"
                                :label="$t('knowledge.fields.bucket')"
                                :rules="[v => !!v || $t('knowledge.validation.bucketRequired')]"
                                required
                            />
                        </template>
                        <template v-if="newKnowledge.type === 'recursive_url'">
                            <v-text-field
                                v-model="newKnowledge.url"
                                :label="$t('knowledge.fields.url')"
                                :rules="[
                                    v => !!v || $t('knowledge.validation.urlRequired'),
                                    v => !v || isValidUrl(v) || $t('knowledge.validation.urlInvalid')
                                ]"
                                required
                            />
                        </template>
                        <template v-if="newKnowledge.type === 'jira'">
                            <v-select
                                v-model="newKnowledge.jiraProject"
                                :label="$t('knowledge.fields.jiraProject')"
                                :items="jiraProjects"
                                :rules="[v => !!v || $t('knowledge.validation.jiraProjectRequired')]"
                                required
                            />
                            <v-text-field
                                v-model="newKnowledge.jiraCreatedAfter"
                                :label="$t('knowledge.fields.jiraCreatedAfter')"
                                :hint="$t('knowledge.fields.jiraCreatedAfterHint')"
                                persistent-hint
                                type="date"
                            />
                        </template>
                        <template v-if="newKnowledge.type === 'file_loader'">
                            <v-file-input
                                v-model="newKnowledge.file"
                                :label="$t('knowledge.fields.file')"
                                show-size
                                :accept="ACCEPTED_FILE_TYPES"
                                :rules="[v => !!v || $t('knowledge.validation.fileRequired')]"
                                required
                            />
                        </template>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="grey" @click="dialog = false">
                        {{ $t('knowledge.actions.cancel') }}
                    </v-btn>
                    <v-btn color="primary" @click="validateAndSave" :loading="loading">
                        {{ $t('knowledge.actions.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Edit Knowledge Dialog -->
        <v-dialog v-model="editDialog" max-width="600px">
            <v-card>
                <v-card-title>{{ t('knowledge.editKnowledge') }}</v-card-title>
                <v-card-text>
                    <v-form ref="editForm" @submit.prevent="updateKnowledge" v-model="editFormValid">
                        <v-text-field
                            v-model="editedKnowledge.title"
                            :label="t('knowledge.fields.title')"
                            :rules="[v => !!v || t('knowledge.validation.titleRequired')]"
                            required
                        />
                        <v-textarea
                            v-model="editedKnowledge.description"
                            :label="t('knowledge.fields.description')"
                            :rules="[v => !!v || t('knowledge.validation.descriptionRequired')]"
                            required
                        />
                        <v-select
                            v-model="editedKnowledge.type"
                            :label="t('knowledge.fields.type')"
                            :items="knowledgeTypes"
                            :rules="[v => !!v || t('knowledge.validation.typeRequired')]"
                            required
                        />
                        <template v-if="editedKnowledge.type === 's3'">
                            <v-select
                                v-model="editedKnowledge.region"
                                :label="t('knowledge.fields.region')"
                                :items="availableRegions"
                                :rules="[v => !!v || t('knowledge.validation.regionRequired')]"
                                required
                            />
                            <v-text-field
                                v-model="editedKnowledge.bucket"
                                :label="t('knowledge.fields.bucket')"
                                :rules="[v => !!v || t('knowledge.validation.bucketRequired')]"
                                required
                            />
                        </template>
                        <template v-if="editedKnowledge.type === 'recursive_url'">
                            <v-text-field
                                v-model="editedKnowledge.url"
                                :label="t('knowledge.fields.url')"
                                :rules="[
                                    v => !!v || t('knowledge.validation.urlRequired'),
                                    v => !v || isValidUrl(v) || t('knowledge.validation.urlInvalid')
                                ]"
                                required
                            />
                        </template>

                        <template v-if="editedKnowledge.type === 'jira'">
                            <v-select
                                v-model="editedKnowledge.jiraProject"
                                :label="t('knowledge.fields.jiraProject')"
                                :items="jiraProjects"
                                :rules="[v => !!v || t('knowledge.validation.jiraProjectRequired')]"
                                required
                            />
                            <v-text-field
                                v-model="editedKnowledge.jiraCreatedAfter"
                                :label="t('knowledge.fields.jiraCreatedAfter')"
                                :hint="t('knowledge.fields.jiraCreatedAfterHint')"
                                persistent-hint
                                type="date"
                            />
                        </template>

                        <template v-if="editedKnowledge.type === 'file_loader'">
                            <v-file-input
                                v-model="editedKnowledge.file"
                                :label="t('knowledge.fields.file')"
                                show-size
                                :accept="ACCEPTED_FILE_TYPES"
                                :rules="[v => !!v || t('knowledge.validation.fileRequired')]"
                                required
                            />
                        </template>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="justgray" @click="editDialog = false">
                        {{ t('knowledge.actions.cancel') }}
                    </v-btn>
                    <v-btn color="violean" @click="validateAndUpdate" :loading="loading">
                        {{ t('knowledge.actions.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-data-table :headers="headers" :items="knowledgeItems" :loading="loading" :search="search"
            class="pt-sans-table" v-model:expanded="expanded" show-expand :items-per-page="store.itemsPerPage"
            @update:items-per-page="store.setItemsPerPage">
            <template v-slot:expanded-row="{ columns, item }">
                <tr>
                    <td :colspan="columns.length">
                        <template v-if="item.type === 's3'">
                            {{ t('knowledge.fields.region') }}: {{ item.region }} / {{ t('knowledge.fields.bucket') }}:
                            {{ item.bucket }}
                        </template>
                        <template v-if="item.type === 'recursive_url'">
                            {{ t('knowledge.fields.url') }}: {{ item.url }}
                        </template>
                        <template v-if="item.type === 'jira'">
                            {{ t('knowledge.fields.jiraProject') }}: {{ item.jiraProject }}
                            {{ item.jiraCreatedAfter ? `- ${t('knowledge.fields.jiraCreatedAfter')}: ${new
                                Date(item.jiraCreatedAfter).toLocaleDateString(locale)}` : '' }}
                        </template>
                        <template v-if="item.type === 'file_loader'">
                            {{ t('knowledge.fields.file') }}: {{ item.file }}
                        </template>
                    </td>
                </tr>
            </template>

            <template v-slot:item.status="{ item }">
                <v-tooltip :text="item.processedAt ? t('knowledge.fields.lastProcessed', {
                    date: new Date(item.processedAt).toLocaleString()
                }) : ''">
                    <template v-slot:activator="{ props }">
                        <v-chip v-bind="item.processedAt ? props : {}" :color="item.processedAt ? 'success' : 'warning'"
                            :text="item.processedAt ? t('knowledge.fields.processed') : t('knowledge.fields.pending')"
                            size="small" />
                    </template>
                </v-tooltip>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon size="large" color="success" class="me-1" @click="processKnowledge(item)">
                    mdi-play
                </v-icon>
                <v-icon size="small" class="me-1" color="info" @click="editKnowledge(item)">
                    mdi-pencil
                </v-icon>
                <v-icon size="small" color="error" @click="openDeleteDialog(item)">
                    mdi-delete
                </v-icon>
            </template>

            <template v-slot:item.updatedAt="{ item }">
                {{ new Date(item.updatedAt).toLocaleString() }}
            </template>
        </v-data-table>

        <v-dialog v-model="processDialog" max-width="400px">
            <v-card>
                <v-card-title>{{ t('knowledge.actions.process') }}</v-card-title>
                <v-card-text>
                    {{ t('knowledge.actions.processConfirm') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="grey" @click="processDialog = false">{{ t('knowledge.actions.decline') }}</v-btn>
                    <v-btn color="error" @click="confirmProcess" :loading="loading">
                        {{ t('knowledge.actions.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteDialog" max-width="400px">
            <v-card>
                <v-card-title>{{ t('knowledge.actions.delete') }}</v-card-title>
                <v-card-text>
                    {{ t('dialog.deleteConfirm') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="grey" @click="deleteDialog = false">{{ t('knowledge.actions.cancel') }}</v-btn>
                    <v-btn color="error" @click="confirmDelete" :loading="loading">
                        {{ t('knowledge.actions.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { apiClient } from '@/services/api';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'
import { userStore } from '../stores/userStore'
import { storeToRefs } from 'pinia'
import { useSnackbarStore } from '@/stores/snackbarStore'

const ACCEPTED_FILE_TYPES = '.pdf,.txt,.md';

const { email } = storeToRefs(userStore())

const knowledgeItems = ref([]);
const loading = ref(true);
const search = ref('');
const router = useRouter();
const dialog = ref(false);
const { t, locale } = useI18n()

const newKnowledge = ref({
    title: '',
    description: '',
    type: '',
    region: '',
    bucket: '',
    url: '',
    jiraProject: '',
    jiraCreatedAfter: '',
    file: null
});
const editDialog = ref(false);
const editedKnowledge = ref({
    id: '',
    title: '',
    description: '',
    type: '',
    region: '',
    bucket: '',
    url: '',
    jiraProject: '',
    jiraCreatedAfter: '',
    file: null
});
const processDialog = ref(false);
const selectedKnowledge = ref(null);
const snackbarStore = useSnackbarStore()
const deleteDialog = ref(false);
const knowledgeToDelete = ref(null);

const headers = computed(() => [
    { title: t('knowledge.fields.title'), key: 'title', align: 'start' },
    { title: t('knowledge.fields.description'), key: 'description' },
    { title: t('knowledge.fields.type'), key: 'type' },
    { title: t('knowledge.fields.lastUpdate'), key: 'updatedAt' },
    { title: '', key: 'status' },
    { title: '', key: 'actions', sortable: false, align: 'end' }
]);

const availableRegions = ['eu-west-1', 'eu-west-3'];

const knowledgeTypes = {
    s3: t('knowledge.types.s3'),
    recursive_url: t('knowledge.types.recursive_url'),
    jira: t('knowledge.types.jira'),
    file_loader: t('knowledge.types.file_loader')
};

const expanded = ref([]);

const isValidUrl = (url) => {
    try {
        new URL(url);
        return true;
    } catch {
        return false;
    }
};

const form = ref(null);
const formValid = ref(false);
const editForm = ref(null);
const editFormValid = ref(false);

const validateAndSave = async () => {
    const { valid } = await form.value.validate();
    
    if (valid) {
        saveKnowledge();
    }
};

const validateAndUpdate = async () => {
    const { valid } = await editForm.value.validate();
    
    if (valid) {
        updateKnowledge();
    }
};

const fetchKnowledge = async () => {
    try {
        loading.value = true;
        const response = await apiClient.get('/knowledges');
        knowledgeItems.value = Array.isArray(response.data) ? response.data : [];
    } catch (error) {
        console.error('Error fetching knowledge:', error);
        knowledgeItems.value = []; // Ensure knowledgeItems is always an array even on error
    } finally {
        loading.value = false;
    }
};

const createKnowledge = () => {
    dialog.value = true;
};

const editKnowledge = (item) => {
    editedKnowledge.value = {
        id: item.id,
        title: item.title,
        description: item.description,
        type: item.type,
        region: item.region || '',
        bucket: item.bucket || '',
        url: item.url || '',
        jiraProject: item.jiraProject || '',
        jiraCreatedAfter: item.jiraCreatedAfter || '',
        file: item.file
    };
    editDialog.value = true;
};

const deleteKnowledge = async (knowledge) => {
    try {
        loading.value = true;
        await apiClient.delete(`/knowledges/${knowledge.id}`);
        await fetchKnowledge();
    } catch (error) {
        console.error('Error deleting knowledge:', error);
    } finally {
        loading.value = false;
    }
};

const saveKnowledge = async () => {
    // Validate common required fields
    if (!newKnowledge.value.title || !newKnowledge.value.description || !newKnowledge.value.type) {
        snackbarStore.showMessage(t('knowledge.validation.requiredFields'), 'error');
        return;
    }

    // Type-specific validation
    if (newKnowledge.value.type === 's3') {
        if (!newKnowledge.value.region || !newKnowledge.value.bucket) {
            snackbarStore.showMessage(t('knowledge.validation.s3Fields'), 'error');
            return;
        }
    } else if (newKnowledge.value.type === 'recursive_url') {
        if (!newKnowledge.value.url) {
            snackbarStore.showMessage(t('knowledge.validation.urlRequired'), 'error');
            return;
        }
        if (!isValidUrl(newKnowledge.value.url)) {
            snackbarStore.showMessage(t('dialog.invalidUrl'), 'error');
            return;
        }
    } else if (newKnowledge.value.type === 'jira') {
        if (!newKnowledge.value.jiraProject) {
            snackbarStore.showMessage(t('knowledge.validation.jiraProjectRequired'), 'error');
            return;
        }
    } else if (newKnowledge.value.type === 'file_loader') {
        if (!newKnowledge.value.file) {
            snackbarStore.showMessage(t('knowledge.validation.fileRequired'), 'error');
            return;
        }
    }

    try {
        loading.value = true;

        // Create FormData for file upload
        const formData = new FormData();
        formData.append('title', newKnowledge.value.title);
        formData.append('description', newKnowledge.value.description);
        formData.append('type', newKnowledge.value.type);
        formData.append('region', newKnowledge.value.region || '');
        formData.append('bucket', newKnowledge.value.bucket || '');
        formData.append('url', newKnowledge.value.url || '');
        formData.append('jiraProject', newKnowledge.value.jiraProject || '');
        formData.append('jiraCreatedAfter', newKnowledge.value.jiraCreatedAfter || '');

        // Append file if present
        if (newKnowledge.value.file) {
            formData.append('file', newKnowledge.value.file);
        }

        await apiClient.post('/knowledges', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        await fetchKnowledge();
        dialog.value = false;
        newKnowledge.value = { title: '', description: '', type: '', region: '', bucket: '', url: '', jiraProject: '', jiraCreatedAfter: '', file: null };
        snackbarStore.showMessage(t('knowledge.success.created'), 'success');
    } catch (error) {
        console.error('Error creating knowledge:', error);
        snackbarStore.showMessage(t('knowledge.error.create'), 'error');
    } finally {
        loading.value = false;
    }
};

const updateKnowledge = async () => {
    if (editedKnowledge.value.type === 'recursive_url' && !isValidUrl(editedKnowledge.value.url)) {
        alert(t('dialog.invalidUrl'));
        return;
    }
    try {
        loading.value = true;
        await apiClient.put(`/knowledges/${editedKnowledge.value.id}`, editedKnowledge.value);
        await fetchKnowledge();
        editDialog.value = false;
    } catch (error) {
        console.error('Error updating knowledge:', error);
    } finally {
        loading.value = false;
    }
};

const processKnowledge = (item) => {
    selectedKnowledge.value = item;
    processDialog.value = true;
};

const confirmProcess = async () => {
    try {
        loading.value = true;

        await apiClient.post('/activities', {
            user: email.value,
            action: 'knowledge_processing',
            details: `Processing knowledge ${selectedKnowledge.value.title}`,
            status: 'started'
        });
        const response = await apiClient.post(`/knowledges/${selectedKnowledge.value.id}/process`);
        if (response.status === 202) {
            snackbarStore.showMessage('Document processing initiated', 'success')
            processDialog.value = false;
        }
    } catch (error) {
        snackbarStore.showMessage('Error processing knowledge: ' + (error.message || 'Unknown error'), 'error')
    } finally {
        loading.value = false;
        selectedKnowledge.value = null;
    }
};

const openDeleteDialog = (knowledge) => {
    knowledgeToDelete.value = knowledge;
    deleteDialog.value = true;
};

const confirmDelete = async () => {
    try {
        await deleteKnowledge(knowledgeToDelete.value);
        deleteDialog.value = false;
        knowledgeToDelete.value = null;
    } catch (error) {
        console.error('Error confirming delete:', error);
    }
};

const jiraProjects = ['ISS', 'IOBEXP'];

const store = userStore()

onMounted(() => {
    fetchKnowledge();
});
</script>
