<template>
    <div>
        <v-card class="mb-4">
            <v-card-title class="title-markazi">
                {{ $t('prompt.prompts') }}
                <v-spacer />
                <v-btn color="primary" prepend-icon="mdi-plus" @click="dialog = true">
                    {{ $t('prompt.addPrompt') }}
                </v-btn>
            </v-card-title>
        </v-card>

        <!-- Create prompt dialog -->
        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
                <v-card-title>{{ $t('prompt.createPrompt') }}</v-card-title>
                <v-card-text>
                    <v-form ref="form" @submit.prevent="savePrompt" v-model="formValid">
                        <v-text-field
                            v-model="newPrompt.title"
                            :label="$t('prompt.title')"
                            :rules="[v => !!v || $t('prompt.validation.titleRequired')]"
                            required
                        />
                        <v-textarea
                            v-model="newPrompt.content"
                            :label="$t('prompt.content')"
                            :rules="[
                                v => !!v || $t('prompt.validation.contentRequired'),
                                v => !v || v.length >= 10 || $t('prompt.validation.contentTooShort'),
                                v => !v || v.length <= 1000 || $t('prompt.validation.contentTooLong')
                            ]"
                            required
                        />
                        <v-select
                            v-model="newPrompt.provider"
                            :label="$t('prompt.provider')"
                            :items="Object.keys(providerModels)"
                            :rules="[v => !!v || $t('prompt.validation.providerRequired')]"
                            required
                            @update:model-value="newPrompt.model = ''"
                        />
                        <v-select
                            v-model="newPrompt.model"
                            :label="$t('prompt.model')"
                            :items="availableModels"
                            :rules="[v => !!v || $t('prompt.validation.modelRequired')]"
                            required
                            :disabled="!newPrompt.provider"
                        />
                        <v-select
                            v-model="newPrompt.knowledgeId"
                            :label="$t('menu.knowledge')"
                            :items="knowledges"
                            item-title="title"
                            item-value="id"
                            clearable
                            :hint="$t('prompt.selectRag')"
                            persistent-hint
                        />
                        <v-slider
                            v-model="newPrompt.temperature"
                            :label="$t('prompt.temperature')"
                            min="0"
                            max="1"
                            step="0.1"
                            thumb-label
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="justgray" @click="dialog = false">
                        {{ $t('prompt.askWithoutSaving') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn color="justgray" @click="dialog = false">
                        {{ $t('dialog.cancel') }}
                    </v-btn>
                    <v-btn color="violean" @click="validateAndSave" :loading="loading">
                        {{ $t('dialog.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Edit prompt dialog -->
        <v-dialog v-model="editDialog" max-width="600px">
            <v-card>
                <v-card-title>
                    {{ $t('prompt.editPrompt') }}
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="updatePrompt">
                        <v-text-field v-model="editedPrompt.title" :label="$t('prompt.title')" required />
                        <v-textarea v-model="editedPrompt.content" :label="$t('prompt.content')" required />
                        <v-select v-model="editedPrompt.provider" :label="$t('prompt.provider')"
                            :items="Object.keys(providerModels)" required
                            @update:model-value="editedPrompt.model = ''" />
                        <v-select v-model="editedPrompt.model" :label="$t('prompt.model')" :items="availableModelsEdit"
                            required :disabled="!editedPrompt.provider" />
                        <v-select v-model="editedPrompt.knowledgeId" :label="$t('menu.knowledge')" :items="knowledges"
                            item-title="title" item-value="id" clearable :hint="$t('prompt.selectRag')"
                            persistent-hint />
                        <v-slider v-model="editedPrompt.temperature" :label="$t('prompt.temperature')" min="0" max="1"
                            step="0.1" thumb-label />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="secondary" @click="improvePrompt">
                        {{ $t('prompt.improvePrompt') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn color="justgray" @click="editDialog = false">
                        {{ $t('dialog.cancel') }}
                    </v-btn>
                    <v-btn color="violean" @click="updatePrompt" :loading="loading">
                        {{ $t('dialog.update') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 'Improve my prompt' dialog -->
        <v-dialog v-model="improveDialog" max-width="1200px">
            <v-card>
                <v-card-title>{{ $t('prompt.improvePrompt') }}</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-textarea v-model="editedPrompt.content" :label="$t('prompt.currentPrompt')" auto-grow
                                rows="6" variant="outlined" counter="1000" />
                            <v-row justify="center">
                                <v-btn color="primary" class="mt-2" @click="improvePrompt" :loading="processingOnly"
                                    v-show="currentAnswer">
                                    {{ $t('prompt.generateResponse') }}
                                </v-btn>
                            </v-row>
                            <v-row>
                                <div v-if="improvements" class="improvements-container">
                                    <h4 class="mb-2">{{ $t('prompt.improvements') }}</h4>
                                    <v-card class="pa-0" elevation="0" border>
                                        <template v-for="(section, sectionIndex) in [
                                            {
                                                items: improvements.good_things,
                                                title: $t('prompt.strengths'),
                                                icon: 'mdi-check-circle',
                                                color: 'green',
                                                textClass: 'text-green-darken-1'
                                            },
                                            {
                                                items: improvements.bad_things,
                                                title: $t('prompt.weaknesses'),
                                                icon: 'mdi-alert-circle',
                                                color: 'red',
                                                textClass: 'text-red-darken-1'
                                            }
                                        ]" :key="sectionIndex">
                                            <div :class="[section.bgClass, 'mb-1 pa-4 rounded']">
                                                <div class="d-flex align-center">
                                                    <v-icon :color="section.color" class="me-2">{{ section.icon
                                                        }}</v-icon>
                                                    <span :class="['text-' + section.color, 'text-h8']">{{ section.title
                                                        }}</span>
                                                </div>
                                                <div v-for="(item, index) in section.items"
                                                    :key="sectionIndex + '-' + index" class="ml-8 mt-2">
                                                    <div :class="[section.textClass, 'font-weight-bold']">{{ item.aspect
                                                        }}</div>
                                                    <div class="text-body-2">{{ item.description }}</div>
                                                </div>
                                            </div>
                                        </template>
                                    </v-card>
                                </div>
                            </v-row>
                        </v-col>
                        <v-col cols="6" class="d-flex">
                            <div v-html="sanitizedCurrentAnswer" :class="[
                                'response-textarea',
                                'pa-4',
                                'bordered-div',
                                theme.global.current.value.dark ? 'response-textarea-dark' : 'response-textarea-light'
                            ]" style="height: 100%; width: 100%; overflow-y: auto" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="justgray" @click="improveDialog = false">
                        {{ $t('dialog.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Prompts table -->
        <v-data-table :headers="headers" :items="prompts" :loading="loading" :search="search" class="pt-sans-table"
            density="comfortable" :items-per-page="store.itemsPerPage" @update:items-per-page="store.setItemsPerPage">
            <template v-slot:item.rag="{ item }">
                <v-tooltip
                    :text="$t('prompt.usesRag', { type: knowledges.find(knowledge => knowledge.id === item.knowledgeId)?.type })"
                    location="end">
                    <template v-slot:activator="{ props }">
                        <v-chip v-if="item.knowledgeId" v-bind="props" color="success" size="small">
                            <v-icon size="small">
                                mdi-file-document-outline
                            </v-icon>
                        </v-chip>
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip :text="$t('prompt.processPrompt')" location="end">
                    <template v-slot:activator="{ props }">
                        <v-icon v-bind="props" size="large" color="apple" class="me-2" @click="processPrompt(item)">
                            mdi-play
                        </v-icon>
                    </template>
                </v-tooltip>

                <v-tooltip :text="$t('prompt.editPrompt')" location="end">
                    <template v-slot:activator="{ props }">
                        <v-icon v-bind="props" size="small" color="#9D9D9F" class="me-2" @click="editPrompt(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                </v-tooltip>

                <v-tooltip :text="$t('prompt.deletePrompt')" location="end">
                    <template v-slot:activator="{ props }">
                        <v-icon v-bind="props" size="small" color="error" @click="deletePrompt(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.provider="{ item }">
                <div class="d-flex align-center">
                    <img :src="`${item.provider}.png`" :alt="item.provider" class="me-2" width="20" height="20"
                        rounded="xl" />
                    {{ item.provider }}
                </div>
            </template>
        </v-data-table>

        <!-- Prompt response dialog -->
        <v-dialog v-model="responseDialog" max-width="800px" transition="dialog-bottom-transition">
            <v-card class="resizable">
                <v-card-title>
                    {{ $t('prompt.promptResponse') }}
                </v-card-title>
                <v-card-text>
                    <div v-html="sanitizedMarkdown" :class="[
                        'response-textarea',
                        'pa-4',
                        'bordered-div',
                        theme.global.current.value.dark ? 'response-textarea-dark' : 'response-textarea-light'
                    ]" style="min-height: 300px; overflow-y: auto" />
                </v-card-text>
                <v-card-actions>
                    <v-tooltip :text="$t('dialog.copyToClipboard')" location="start">
                        <template v-slot:activator="{ props }">
                            <v-icon v-bind="props" @click="copyToClipboard(promptResponse)" color="primary"
                                class="cursor-pointer">
                                mdi-content-copy
                            </v-icon>
                        </template>
                    </v-tooltip>
                    <v-spacer />
                    <v-btn color="primary" @click="responseDialog = false">
                        {{ $t('dialog.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { apiClient } from '@/services/api';
import { useI18n } from 'vue-i18n';
import { useSnackbarStore } from '@/stores/snackbarStore'
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { useTheme } from 'vuetify'
import { userStore } from '@/stores/userStore'
const store = userStore()

const prompts = ref([]);
const loading = ref(true);
const search = ref('');
const providerFilter = ref('');
const modelFilter = ref('');
const dialog = ref(false);
const editDialog = ref(false);
const improveDialog = ref(false);
const currentAnswer = ref('');
const improvingPrompt = ref(false);
const processingOnly = ref(false)
const newPrompt = ref({
    userId: "1",
    title: '',
    content: '',
    provider: '',
    model: '',
    temperature: 0.7,
    knowledgeId: null
});
const editedPrompt = ref({
    id: '',
    userId: "1",
    title: '',
    content: '',
    provider: '',
    model: '',
    temperature: 0.7,
    knowledgeId: null
});
const responseDialog = ref(false);
const promptResponse = ref('');
const snackbarStore = useSnackbarStore()
const knowledges = ref([]);
const { t, locale } = useI18n()
const theme = useTheme()

const headers = computed(() => [
    { title: t('prompt.title'), key: 'title', align: 'start' },
    { title: t('prompt.content'), key: 'content' },
    { title: t('prompt.provider'), key: 'provider' },
    { title: t('prompt.model'), key: 'model' },
    { title: t('prompt.temperature'), key: 'temperature' },
    { title: '', key: 'rag' },
    { title: '', key: 'actions', sortable: false, align: 'end' }
]);

const providerModels = {
    'openai': ['gpt-4o-2024-11-20', 'gpt-4o-mini', 'o1-preview', 'o1-mini', 'gpt-4-turbo', 'gpt-4', 'gpt-3.5-turbo'],
    'anthropic': ['claude-3-5-sonnet-latest', 'claude-3-5-haiku-latest', 'claude-3-opus-latest', 'claude-3-sonnet-20240229', 'claude-3-haiku-20240307'],
    'mistral': ['mistral-large-latest', 'pixtral-large-latest', 'ministral-3b-latest', 'ministral-8b-latest', 'mistral-small-latest', 'codestral-latest'],
    'google': ['gemini-2.0-flash-exp', 'gemini-1.5-flash', 'gemini-1.5-flash-8b', 'gemini-1.5-pro', 'gemini-1.0-pro']
};

const availableModels = computed(() => {
    return providerModels[newPrompt.value.provider] || [];
});

const availableModelsEdit = computed(() => {
    return providerModels[editedPrompt.value.provider] || [];
});

const fetchPrompts = async () => {
    try {
        loading.value = true;
        const response = await apiClient.get('/prompts');
        prompts.value = Array.isArray(response.data) ? response.data : [];
    } catch (error) {
        console.error('Error fetching prompts:', error);
        prompts.value = []; // Ensure prompts is always an array even on error
    } finally {
        loading.value = false;
    }
};

const fetchKnowledges = async () => {
    try {
        const response = await apiClient.get('/knowledges');
        knowledges.value = response.data;
    } catch (error) {
        console.error('Error fetching knowledges:', error);
    }
};

const createPrompt = () => {
    newPrompt.value = {
        userId: "1",
        title: '',
        content: '',
        provider: '',
        model: '',
        temperature: 0.7,
        knowledgeId: null
    };
    dialog.value = true;
};

const editPrompt = (item) => {
    editedPrompt.value = {
        id: item.id,
        userId: item.userId,
        title: item.title,
        content: item.content,
        provider: item.provider,
        model: item.model,
        temperature: item.temperature,
        knowledgeId: item.knowledgeId
    };
    editDialog.value = true;
};

const deletePrompt = async (prompt) => {
    try {
        loading.value = true;
        await apiClient.delete(`/prompts/${prompt.id}`);
        await fetchPrompts();
    } catch (error) {
        console.error('Error deleting prompt:', error);
    } finally {
        loading.value = false;
    }
};

const savePrompt = async () => {
    try {
        loading.value = true;
        await apiClient.post('/prompts', newPrompt.value);
        await fetchPrompts();
        dialog.value = false;
        newPrompt.value = {
            userId: "1",
            title: '',
            content: '',
            provider: '',
            model: '',
            temperature: 0.7,
            knowledgeId: null
        };
    } catch (error) {
        console.error('Error creating prompt:', error);
    } finally {
        loading.value = false;
    }
};

const updatePrompt = async () => {
    try {
        loading.value = true;
        await apiClient.put(`/prompts/${editedPrompt.value.id}`, editedPrompt.value);
        await fetchPrompts();
        editDialog.value = false;
    } catch (error) {
        console.error('Error updating prompt:', error);
    } finally {
        loading.value = false;
    }
};

const processPrompt = async (item) => {
    try {
        loading.value = true;
        responseDialog.loading = true;
        const response = await apiClient.post(`/prompts/${item.id}/process`);
        if (response.status === 200) {
            promptResponse.value = "> #### 🤨\n\n" + item.content + "\n> #### 🧠\n\n" + response.data.response;
            responseDialog.value = true;
        } else {
            snackbarStore.showMessage('Error processing prompt: ' + response.data, 'error')
        }
    } catch (error) {
        snackbarStore.showMessage('Error processing prompt: ' + (error.message || 'Unknown error'), 'error')
    } finally {
        loading.value = false;
    }
};

const processWithoutSave = async () => {
    // todo
}

const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
        snackbarStore.showMessage(t('dialog.copiedToClipboard'), 'success')
    } catch (err) {
        snackbarStore.showMessage(t('dialog.copyToClipboardFailed'), 'error')
    }
};

const improvements = ref(null);

const improvePrompt = async () => {
    improveDialog.value = true;
    improveDialog.disabled = true;
    improvingPrompt.value = true;

    currentAnswer.value = '';
    improvements.value = null;

    // Get language
    let language;
    switch (locale.value) {
        case 'fr':
            language = 'French';
            break;
        default:
            language = 'English';
    }

    try {

        const temporaryPrompt = {
            provider: editedPrompt.value.provider,
            model: editedPrompt.value.model,
            temperature: editedPrompt.value.temperature,
            knowledgeId: editedPrompt.value.knowledgeId,
            title: editedPrompt.value.title,
            content: editedPrompt.value.content
        };

        const results = await Promise.allSettled([
            apiClient.post(`/prompts/${editedPrompt.value.id}/process?lang=${language}`, temporaryPrompt),
            apiClient.post(`/prompts/${editedPrompt.value.id}/improve?lang=${language}`, temporaryPrompt)
        ]);

        improveDialog.disabled = false;
        // Handle process response
        if (results[0].status === 'fulfilled') {
            currentAnswer.value = results[0].value.data.response;
        } else {
            snackbarStore.showMessage('Error processing prompt: ' + (results[0].reason?.message || 'Unknown error'), 'error');
        }

        // Handle improve response
        if (results[1].status === 'fulfilled') {
            improvements.value = JSON.parse(results[1].value.data.response.split('\n').slice(1, -1).join('\n'));
        } else {
            snackbarStore.showMessage('Error improving prompt: ' + (results[1].reason?.message || 'Unknown error'), 'error');
        }

    } catch (error) {
        snackbarStore.showMessage('Error improving prompt: ' + (error.message || 'Unknown error'), 'error')
        console.log(improvementsResponse.data.response);
    } finally {
        improvingPrompt.value = false;
    }
};

const formattedStrengths = computed(() => {
    return improvements.value?.good_things?.map(item => `${item.aspect}: ${item.description}`).join('\n') || '';
});

const sanitizedMarkdown = computed(() => {
    return DOMPurify.sanitize(marked(promptResponse.value));
});

const sanitizedCurrentAnswer = computed(() => {
    return DOMPurify.sanitize(marked(currentAnswer.value));
});

const form = ref(null);
const formValid = ref(false);

const validateAndSave = async () => {
    const { valid } = await form.value.validate();

    if (valid) {
        savePrompt();
    }
};

onMounted(() => {
    fetchPrompts();
    fetchKnowledges();
});
</script>

<style scoped>
.response-textarea-light {
    background-color: #beaa7d;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 16px;
}

.response-textarea-dark {
    background-color: #4a4a4a;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 16px;
}

.bordered-div {
    border: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
    border-radius: 4px;
}

.text-green {
    color: green;
}

.text-red {
    color: red;
}

.resizable {
    resize: both;
    overflow: auto;
    min-width: 400px;
    min-height: 400px;
}
</style>
