import { userStore } from '../../stores/userStore'
import axios, { AxiosInstance } from 'axios'
import { login } from '../entraID'
import { useSnackbarStore } from '../../stores/snackbarStore'

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'https://iis-api-rod.iobeya-dev.net'

export class ApiService {
  private static instance: ApiService
  private api: AxiosInstance

  private constructor() {
    this.api = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      }
    })

    // Request interceptor
    this.api.interceptors.request.use(
      (config) => {
        config.headers['Cache-Control'] = 'no-store, no-cache, must-revalidate, proxy-revalidate';
        config.headers['Pragma'] = 'no-cache';
        config.headers['Expires'] = '0';

        // Add token to headers
        const user = userStore()
        const token = user.accessToken
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        } else {
          console.log('No token found')
        }

        // Add timestamp to URL to prevent disk cache
        const timestamp = new Date().getTime()
        config.url = `${config.url}${config.url?.includes('?') ? '&' : '?'}_t=${timestamp}`

        return config;
      },
      (error) => Promise.reject(error)
    )

    // Response interceptor
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        const snackbarStore = useSnackbarStore()

        if (error.response?.status === 401) {
          // Handle unauthorized
          const user = userStore()
          user.setInfo('', '', '', '', '')
          login()
        } else if (error.response?.status === 404) {
          snackbarStore.showMessage('Resource not found', 'error')
        } else if (error.response?.status >= 500) {
          snackbarStore.showMessage('Server error occurred', 'error')
        } else {
          snackbarStore.showMessage('An error occurred: ' + (error.message || 'Unknown error'), 'error')
        }

        return Promise.reject(error)
      }
    )
  }

  public static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService()
    }
    return ApiService.instance
  }

  public getApi(): AxiosInstance {
    return this.api
  }
}

export const apiClient = ApiService.getInstance().getApi()
