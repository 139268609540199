import { defineStore } from 'pinia'

interface UserState {
  firstname: string | null
  lastname: string | null
  email: string | null
  idToken: string | null
  accessToken: string | null
  itemsPerPage: number
}

export const userStore = defineStore('user', {
  state: (): UserState => ({
    firstname: null,
    lastname: null,
    email: null,
    idToken: null,
    accessToken: null,
    itemsPerPage: 10
  }),
  actions: {
    setInfo(firstname: string, lastname: string, email: string, idToken: string, accessToken: string) {
      this.firstname = firstname
      this.lastname = lastname
      this.email = email
      this.idToken = idToken
      this.accessToken = accessToken
    },
    setItemsPerPage(items: number) {
      this.itemsPerPage = items
    }
  }
})
