import { createRouter, createWebHistory, } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PromptView from '../views/PromptView.vue'
import KnowledgeView from '../views/KnowledgeView.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'
import WelcomePage from '../views/WelcomePage.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'welcome',
            component: WelcomePage
        },
        {
            path: '/',
            component: DefaultLayout,
            children: [
                {
                    path: 'home',
                    name: 'home',
                    component: HomeView
                },
                {
                    path: 'prompt',
                    name: 'prompt',
                    component: PromptView
                },
                {
                    path: 'knowledge',
                    name: 'knowledge',
                    component: KnowledgeView
                }
            ]
        }
    ]
})

// Navigation guard
router.beforeEach((_to, _from, next) => {
    next()
})

export default router
