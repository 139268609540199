import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { jwtDecode } from 'jwt-decode';
import { userStore } from '../stores/userStore'

export const msalConfig = {
    auth: {
        clientId: "d0cd2ed8-0ec6-49e9-b2c6-70e0366a157c",
        authority: "https://login.microsoftonline.com/26dce9f0-a8ed-4298-8698-a1f6655f9111"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    }
};

const pca = new PublicClientApplication(msalConfig);

// Login (with popup)
export const login = async () => {
    try {
        if (window.opener) {
            alert('Please allow popups to continue');
        }

        try {
            await pca.handleRedirectPromise();
        } catch (error) {
            await pca.initialize();
        }

        await pca.loginPopup({
            scopes: ["openid", "profile", "User.Read"]
        });
        console.log('Login successful');
        window.location.reload();
    } catch (error) {
        console.error('Login error:', error);
    }
};

// Logout (with popup)
export const logout = () => {
    pca.logoutPopup();
};

// Silent login
export const silentLogin = async () => {
    try {
        await pca.handleRedirectPromise();
    } catch (error) {
        await pca.initialize();
    }

    try {
        const accounts = pca.getAllAccounts();
        if (accounts.length === 0) {
            console.log('No accounts found, redirecting to login.');
            await login();
        }

        const request = {
            scopes: ["openid", "profile", "User.Read"],
            account: accounts[0]
        };

        pca.acquireTokenSilent(request).then(tokenResponse => {
            const { name, preferred_username } = jwtDecode(tokenResponse.idToken) as {
                name: string,
                preferred_username: string
            };
            const [firstName, ...lastNames] = name.split(' ');
            const lastName = lastNames.join(' ');
            const user = userStore();
            user.setInfo(firstName, lastName, preferred_username, tokenResponse.idToken, tokenResponse.accessToken);
        }).catch(async (error) => {
            if (error instanceof InteractionRequiredAuthError) {
                return pca.acquireTokenPopup(request);
            }
            console.error('Silent login error:', error);
        })
    } catch (error) {
        console.error('Silent login error:', error);
    }
}

// export const protectedResources = {
//     aiApi: {
//         endpoint: 'https://iis-api.iobeya-dev.net/',
//         scopes: {
//             read: ['api://100da743-edf7-4de2-ae02-3d1d5a6cc864/AI.Write'],
//             write: ['api://100da743-edf7-4de2-ae02-3d1d5a6cc864/AI.Write'],
//         },
//     },
// };

// export const loginRequest = {
//     scopes: [...protectedResources.aiApi.scopes.read, ...protectedResources.aiApi.scopes.write],
// };
