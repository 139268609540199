<template>
    <div>
        <!-- Welcome Section -->
        <v-row class="mb-6">
            <v-col cols="12">
                <v-card class="mb-4">
                    <v-card-title class="title-markazi">
                        {{ $t('home.welcome', { name: firstname }) }}
                    </v-card-title>
                    <v-card-subtitle class="sub-title-markazi">
                        {{ $t('home.subtitle') }}
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>

        <!-- Stats Cards -->
        <v-row>
            <v-col cols="4">
                <v-card class="mx-auto" max-width="600" color="elderberry">
                    <template v-slot:prepend>
                        <v-icon color="blueberry" class="me-8" icon="mdi-message-text" size="56" />
                    </template>
                    <template v-slot:title>
                        <div class="text-caption text-grey text-uppercase">
                            {{ $t('menu.prompt') }}
                        </div>
                        <span class="text-h3 font-weight-black" v-text="promptCount"></span>
                        <strong> {{ $t('home.created') }}</strong>
                    </template>
                    <v-sheet color="transparent">
                        <v-sparkline :model-value="weeklyPromptCounts" :fill="true" :gradient="['#001D5E', '#8FAEF4']"
                            :line-width="2" smooth padding="4" auto-draw />
                    </v-sheet>
                </v-card>

            </v-col>
            <v-col cols="4">
                <v-card class="mx-auto" max-width="600" color="elderberry">
                    <template v-slot:prepend>
                        <v-icon color="blueberry" class="me-8" icon="mdi-brain" size="56" />
                    </template>
                    <template v-slot:title>
                        <div class="text-caption text-grey text-uppercase">
                            {{ $t('menu.knowledge') }}
                        </div>
                        <span class="text-h3 font-weight-black" v-text="knowledgeCount"></span>
                        <strong> {{ $t('home.created') }}</strong>
                    </template>
                    <v-sheet color="transparent">
                        <v-sparkline :model-value="weeklyKnowledgeCounts" :fill="true" :gradient="['#001D5E', '#8FAEF4']"
                            :line-width="2" smooth padding="4" auto-draw />
                    </v-sheet>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-sparkline></v-sparkline>
            </v-col>
        </v-row>

        <!-- Recent Activity -->
        <v-row class="mt-6">
            <v-col cols="12">
                <v-card>
                    <v-card-title class="title-markazi">
                        {{ $t('home.recentActivity') }}
                        <v-btn icon="mdi-refresh" size="small" class="ml-2" @click="refreshData" :loading="isLoading" />
                        <v-spacer />
                        <v-text-field v-model="search" prepend-icon="mdi-magnify" :label="$t('home.search')" single-line
                            hide-details density="compact" />
                    </v-card-title>
                    <v-data-table :headers="headers" :items="activities" :search="search" :loading="isLoading"
                        :sort-by="[{ key: 'updatedAt', order: 'desc' }]" class="pt-sans-table"
                        :items-per-page="store.itemsPerPage"
                        @update:items-per-page="store.setItemsPerPage">
                        <template v-slot:item.status="{ item }">
                            <v-chip :color="getStatusColor(item.status)" size="small" class="text-lowercase">
                                {{ item.status }}
                            </v-chip>
                        </template>
                        <template v-slot:item.updatedAt="{ item }">
                            {{ new Date(item.updatedAt).toLocaleString() }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { apiClient } from '../services/api'
import { useI18n } from 'vue-i18n'
import { userStore } from '../stores/userStore'
import { storeToRefs } from 'pinia'
import { useSnackbarStore } from '@/stores/snackbarStore'
const store = userStore()

/* import { PublicClientApplication } from '@azure/msal-browser' */
// import { msalConfig, silentLogin } from '../services/entraID'

const { firstname } = storeToRefs(userStore())

const isLoading = ref(false)
const search = ref('')
const { t } = useI18n()
const snackbarStore = useSnackbarStore()

// Define activity interface
interface Activity {
    user: string;
    action: string;
    details: string;
    status: string;
    updatedAt: string;
}

// Make headers reactive to locale changes
const headers = computed(() => [
    { title: t('home.users'), key: 'user' },
    { title: t('home.actions'), key: 'action' },
    { title: t('home.details'), key: 'details' },
    { title: t('home.status'), key: 'status' },
    { title: t('home.updatedAt'), key: 'updatedAt', sortable: true }
])

// Define a type for activity items
interface Activity {
    user: string
    action: string
    details: string
    status: string
    updatedAt: string
}

// Activities data with type
const activities = ref<Activity[]>([])

// Status color mapping
const getStatusColor = (status: string): string => {
    const colors: { [key: string]: string } = {
        success: 'success',
        error: 'error',
        warning: 'warning',
        pending: 'info'
    }
    return colors[status.toLowerCase()] || 'default'
}

// Fetch dashboard data
const fetchDashboardData = async () => {
    isLoading.value = true
    try {
        // Fetch activities
        const activitiesResponse = await apiClient.get('/activities')
        activities.value = Array.isArray(activitiesResponse.data) ? activitiesResponse.data : []
    } catch (error: any) {
        snackbarStore.showMessage('Error loading dashboard data: ' + (error.message || 'Unknown error'), 'error')
        activities.value = [] // Ensure activities is always an array
    } finally {
        isLoading.value = false
    }
}

// Refresh data
const refreshData = () => {
    fetchDashboardData()
}

// Initial data fetch
onMounted(() => {
    fetchDashboardData()
})

const weeklyPromptCounts = ref<number[]>([])
const promptCount = ref<number>(0)
const weeklyKnowledgeCounts = ref<number[]>([])
const knowledgeCount = ref<number>(0)

// Fetch stats data
const fetchStatsData = async () => {
    try {
        // Fetch prompt stats
        const promptStatsResponse = await apiClient.get('/prompts/stats/weekly')
        const promptWeeklyCounts = promptStatsResponse.data?.weeklyCounts
        weeklyPromptCounts.value = Array.isArray(promptWeeklyCounts) ?
            promptWeeklyCounts.filter(n => typeof n === 'number') : []

        const promptCountResponse = await apiClient.get('/prompts/stats/count')
        promptCount.value = promptCountResponse.data?.count || 0

        // Fetch knowledge stats
        const knowledgeStatsResponse = await apiClient.get('/knowledges/stats/weekly')
        const knowledgeWeeklyCounts = knowledgeStatsResponse.data?.weeklyCounts
        weeklyKnowledgeCounts.value = Array.isArray(knowledgeWeeklyCounts) ?
            knowledgeWeeklyCounts.filter(n => typeof n === 'number') : []

        const knowledgeCountResponse = await apiClient.get('/knowledges/stats/count')
        knowledgeCount.value = knowledgeCountResponse.data?.count || 0
    } catch (error) {
        console.error('Error fetching stats:', error)
        // Set default values in case of error
        weeklyPromptCounts.value = []
        promptCount.value = 0
        weeklyKnowledgeCounts.value = []
        knowledgeCount.value = 0
    }
}

// Initial stats fetch
onMounted(() => {
    fetchStatsData()
})
</script>

<style scoped>
.text-success {
    color: rgb(var(--v-theme-success)) !important;
}

.text-error {
    color: rgb(var(--v-theme-error)) !important;
}
</style>
