import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useSnackbarStore = defineStore('snackbar', () => {
  const show = ref(false)
  const message = ref('')
  const color = ref('success')

  function showMessage(msg: string, type: 'success' | 'error' | 'info' = 'success') {
    message.value = msg
    color.value = type
    show.value = true
  }

  return {
    show,
    message,
    color,
    showMessage
  }
})